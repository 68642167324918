import { Col, Row, Typography } from "antd";
import DividerCustom from "components/_storybooks/DividerCustom";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import CustomCard from "components/CustomCard";
import { DATE_TIME_FORMAT4 } from "config/constants";
import React, { useEffect, useState } from "react";
import StepsCustom from "../../components/StepsCustom";
import { formatDateTime } from "utils/dateTime";

function ControlState({ data, visible }) {
  const [isHidden, setIsHidden] = useState(true);

  const isExpiredDate = data?.status !== "WAITING_FOR_PAYMENT" && data?.expiredDate;

  const renderChildrenExpiredDate = () => {
    if (!data?.expiredDate) {
      return "Đơn chưa hoàn thành";
    }

    return data ? `Hạn chót đối soát ${formatDateTime(data?.expiredDate, DATE_TIME_FORMAT4)}` : DATE_TIME_FORMAT4;
  };

  const handleToggle = () => {
    setIsHidden(!isHidden);
  };

  useEffect(() => {
    setIsHidden(true);
  }, [visible]);

  return (
    <CustomCard
      content={
        <div>
          <SpaceCustom>
            <Row style={{ flexWrap: "wrap" }}>
              <Col xl={18} sm={14}>
                <DividerCustom fontWeight="bold" children={"Trạng thái đối soát"} margin={0} />
              </Col>
              <Col xl={6} sm={10}>
                <DividerCustom
                  colorText="#5B6673"
                  children={renderChildrenExpiredDate()}
                  orientationMargin="0"
                  margin={0}
                />
              </Col>
            </Row>
            <div className={`full-events ${isHidden ? "fade-out" : "fade-in"}`}>
              <StepsCustom dataSteps={data?.fullEvents || []} />
            </div>
          </SpaceCustom>
          <div style={{ textAlign: "center" }}>
            <Typography.Link onClick={handleToggle}>
              {isHidden ? "Xem chi tiết trạng thái đối soát" : "Thu gọn"}
            </Typography.Link>
          </div>
        </div>
      }
    />
  );
}

export default ControlState;
