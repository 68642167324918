import useFiltersTag from "./useFiltersTag";

export const FiltersTag = ({
  params,
  handleRemoveFilterTag,
  staffOptions,
  importNotTypeOptions,
  optionExportRadio,
  warehouseOptions,
  sellersOptions,
}) => {
  const {
    renderTagsCreatedByID,
    renderTagsImportNoteType,
    renderTagsExportNoteType,
    renderTagsExportWarranty,
    renderTagswarehouseIDs,
    renderTagsSellerIDs,
  } = useFiltersTag({
    params,
    handleRemoveFilterTag,
    staffOptions,
    importNotTypeOptions,
    optionExportRadio,
    warehouseOptions,
    sellersOptions,
  });

  return (
    <div style={{ margin: "12px 0" }}>
      {params?.filters?.sellerIDs?.length > 0 ? renderTagsSellerIDs : ""}
      {params?.filters?.warehouseIDs?.length > 0 ? renderTagswarehouseIDs : ""}
      {params?.filters?.exportWarehouseIDs?.length > 0 ? renderTagsExportWarranty : ""}
      {params?.filters?.type?.length > 0 ? renderTagsExportNoteType : ""}
      {params?.filters?.createdByIDs?.length > 0 ? renderTagsCreatedByID : ""}
      {params?.filters?.serialImportNoteType?.length > 0 ? renderTagsImportNoteType : ""}
    </div>
  );
};
