import { Card } from "antd";
import "./index.scss";

function CustomCard({
  title,
  extra,
  content,
  bordered = true,
  defaultActiveTabKey,
  headStyle,
  tabProps,
  tabList,
  contentList,
  size = "small",
  onTabChange,
  activeTabKey,
}) {
  return (
    <Card
      title={title}
      size={size}
      extra={extra}
      bordered={bordered}
      tabList={tabList}
      activeTabKey={activeTabKey}
      headStyle={headStyle || { backgroundColor: "#e7f0ff" }}
      className="custom-card-ant"
      defaultActiveTabKey={defaultActiveTabKey}
      onTabChange={(key) => {
        onTabChange(key);
      }}
      {...tabProps}
    >
      <div>{content}</div>
      <div>{contentList?.[activeTabKey]}</div>
    </Card>
  );
}

export default CustomCard;
