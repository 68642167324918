/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu/FilterLoadMore";
import { useGetBranchQuery } from "hooks/branch";
import { useGetListImportSerial } from "hooks/serial/query";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { isNil, omit } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import FilterMenu from "../../../components/FilterMenu";
import { VALUE_TAGS_IMPORT_NOTE_TYPE, typeImport } from "../../../constant";

const useList = () => {
  const GET_QUERY = getQuery();
  const { data: valueFormSelectCity } = useGetBranchQuery();
  const { branchIdByUser } = useGetUserBranch();

  const [branchesID, setBranchesId] = useState("");
  const onChangeBranchesId = (e) => {
    setBranchesId(e);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      branchesID: e,
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams?.activeKey,
        key: newParams?.key,
        branchesID: newParams?.branchesID,
      },
    });
  };

  const [params, setParams] = useState();

  const { data, paginationData, loading } = useGetListImportSerial(omit(params, ["activeKey", "key"]));

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      branchesID: branchesID,
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams?.activeKey,
        key: newParams?.key,
        branchesID: newParams?.branchesID,
      },
    });
  };

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };
  const handleSort = (by) => {
    if (params.sort[0]?.sortType === "ASC") {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "DESC" }],
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "ASC" }],
      };
      setParams(newParams);
    }
  };

  const columns = [
    {
      title: t("serialIE.stt"),
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: (
        <Typography.Text className="title-table" onClick={() => handleSort("ID")}>
          {t("serialIE.dateImportSerial")}
          <SvgIcon.SortIcon className={params?.sort && params?.sort[0]?.sortType} />
        </Typography.Text>
      ),
      width: 150,
      render: (_, record) => checkNullValue(formatDateTime(record?.createdAt)),
    },
    {
      title: t("serialIE.codeImportSerial"),
      render: (_, record) => checkNullValue(<Link to={`/import-serial/detail/${record?.id}`}>{record?.code}</Link>),
    },
    {
      title: t("serialIE.provider"),
      render: (_, record) => checkNullValue(record?.provider?.fullName || record?.seller?.fullName),
    },
    {
      title: t("serialIE.phoneNumber"),
      render: (_, record) => checkNullValue(record?.provider?.telephone || record?.seller?.telephone),
    },
    {
      title: t("serialIE.totalCategory"),
      width: 120,
      render: (_, record) => checkNullValue(record?.quantityKindOfProduct),
    },
    {
      title: t("serialIE.numberSerialImei"),
      width: 120,
      render: (_, record) => checkNullValue(record?.quantitySerial),
    },
    {
      title: t("serialIE.staffInCharge"),
      render: (_, record) => checkNullValue(record?.createdBy?.fullname),
      filterDropdown: (
        <FilterLoadMore
          params={params}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
    },
    {
      title: t("serialIE.operation"),
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ serialImportNoteType: value })}
          type="checkbox"
          options={VALUE_TAGS_IMPORT_NOTE_TYPE}
        />
      ),
      render: (_, record, index) => typeImport?.[record?.type],
    },
  ];

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      ...params,
      filters: omit({ ...params?.filters }, [`${key}`]),
      pagination: { offset: 0, limit: 10 },
      sort: params.sort || [],
    });

    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
        activeKey: params.activeKey,
        key: params.key,
        branchesID: params?.branchesID,
      },
    });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
      sort: [...params?.sort],
    };

    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: params.activeKey,
        key: params.key,
        branchesID: newParams?.branchesID,
      },
    });
  };

  const handlePrevNextPage = (offset) => {
    const newParams = {
      ...params,
      pagination: {
        offset: offset,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
      sort: [...params?.sort],
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: params.activeKey,
        key: params.key,
        branchesID: newParams?.branchesID,
      },
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: {
          ...newParams?.filters,
          ...newParams?.pagination,
          activeKey: GET_QUERY.activeKey,
          key: GET_QUERY.key,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setParams(() => {
      return {
        // branchesID: branchIdUser,
        filters: {
          query: "",
          createdByIDs: [],
          serialImportNoteType: [],
        },
        pagination: {
          offset: 0,
          limit: 10,
        },
        sort: [
          {
            by: "ID",
            sortType: "DESC",
          },
        ],
      };
    });
    buildQueryString({
      params: { activeKey: "listByImportSerial", key: "listByImportSerial" },
    });
  }, []);

  useEffect(() => {
    setParams({
      branchesID: branchIdByUser,
      filters: {
        query: "",
        createdByIDs: GET_QUERY.createdByIDs || [],
        serialImportNoteType: GET_QUERY.serialImportNoteType || [],
      },
      pagination: {
        offset: GET_QUERY.offset || 0,
        limit: GET_QUERY.limit || 10,
      },
      sort: [
        {
          by: "ID",
          sortType: "DESC",
        },
      ],
    });
  }, [branchIdByUser]);

  useEffect(() => {
    setBranchesId(branchIdByUser);
  }, [branchIdByUser]);

  useEffect(() => {
    buildQueryString({
      params: {
        activeKey: "listByImportSerial",
      },
    });
  }, [params]);

  return {
    valueFormSelectCity,
    columns,
    loading,
    params,
    paramsStaff,
    paginationData,
    data,
    onSearch,
    handleChangeTable,
    onChangeBranchesId,
    branchesID,
    staffs,
    handleRemoveFilterTag,
    handlePrevNextPage,
    handleFilter,
    propsFilterCreated: {
      staffs,
      onLoadMoreData,
      handleSearchStaffs,
      hasMoreData,
    },
  };
};

export default useList;
