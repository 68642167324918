import { Col, Form, Row, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";
import RadioGroupFormCustom from "../RadioGroupFormCustom";
import { useRejectOrderReconcilation } from "hooks/orderReconciliation/mutation";

function ButtonRejectReconciliation({ data }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const { handleRejectOrderReconcilation, loading } = useRejectOrderReconcilation();

  const REJECT_RECONCILIATION = {
    CUSTOMER_REQUEST_RETURN_WITHIN_X_DAYS: "CUSTOMER_REQUEST_RETURN_WITHIN_X_DAYS",
    PROBLEM_POLICY_COLLABORATOR_SELLER: "PROBLEM_POLICY_COLLABORATOR_SELLER",
    OTHER: "OTHER",
  };

  const rejectOptions = [
    {
      label: t("v2.orderReconcilation.change_status.customer_request_return_7_days"),
      value: REJECT_RECONCILIATION.CUSTOMER_REQUEST_RETURN_WITHIN_X_DAYS,
    },
    {
      label: t("v2.orderReconcilation.change_status.affiliate_policy_issue"),
      value: REJECT_RECONCILIATION.PROBLEM_POLICY_COLLABORATOR_SELLER,
    },
    { label: t("v2.orderReconcilation.change_status.other"), value: REJECT_RECONCILIATION.OTHER },
  ];

  const handleOpenModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const onSubmit = async () => {
    await form.validateFields().then(() => {
      const formData = form.getFieldsValue();
      const dataReject = {
        reconcilationID: data?.id,
        reasonInput: {
          cause: formData?.reason !== "OTHER" ? formData?.reason : undefined,
          reason: formData?.freeText,
        },
      };
      handleRejectOrderReconcilation(dataReject)
        .then(() => {
          notify.success({
            message: t("v2.orderReconcilation.change_status.reject_success_confirmation"),
          });
          setVisible(false);
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    });
  };

  return (
    <CustomModal
      footer={false}
      closable={false}
      centered={true}
      title={
        <Typography.Title level={4}>{t("v2.orderReconcilation.change_status.reason_for_rejection")}</Typography.Title>
      }
      customComponent={
        <PrimaryButtonOutLine
          title={t("v2.orderReconcilation.change_status.reject_settlement")}
          onClick={handleOpenModal}
        />
      }
      onOke={onSubmit}
      visible={visible}
      onCancel={closeModal}
      buttonLoading={loading}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space style={{ width: "100%", justifyContent: "left" }}>
            <Typography.Text strong>
              {t("v2.orderReconcilation.change_status.reason_for_rejection")}{" "}
              <span style={{ color: "#D2434D" }}>*</span>
            </Typography.Text>
          </Space>
        </Col>
        <Col span={24}>
          <RadioGroupFormCustom form={form} radioGroupOptions={rejectOptions} />
        </Col>
      </Row>
    </CustomModal>
  );
}

export default ButtonRejectReconciliation;
