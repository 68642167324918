import { Col, Row } from "antd";
import CustomDrawer from "components/CustomDrawer";
import useResizeDrawer from "../helpers/useResizeDrawer";

import ReasonBlock from "../components/ReasonBlock";
import ControlState from "./ControlState";
import HeaderTitle from "./HeaderTitle";
import "./index.scss";
import OrderInfo from "./OrderInfo";
import PaymentInfo from "./PaymentInfo";
import ProductList from "./ProductList";

const defaultDrawerHeight = window.innerHeight * 0.7;
const minDrawerHeight = window.innerHeight * 0.3;
const maxDrawerHeight = window.innerHeight * 0.95;

function ProfitReconciliationDetail({
  visible,
  onClose,
  dataOrderReconciliationDetail,
  loadingOrderReconciliationDetail,
}) {
  const order = dataOrderReconciliationDetail?.order;

  const { drawerHeight, handleMouseDown } = useResizeDrawer({
    defaultHeight: defaultDrawerHeight,
    minHeight: minDrawerHeight,
    maxHeight: maxDrawerHeight,
    visible,
  });

  return (
    <CustomDrawer
      visible={visible}
      onClose={onClose}
      closable={true}
      placement="bottom"
      height={drawerHeight}
      bodyStyle={{ background: "#F7F8FB", height: drawerHeight }}
      maskClosable={false}
      mask={false}
      customTitle={<HeaderTitle data={dataOrderReconciliationDetail} handleMouseDown={handleMouseDown} />}
      content={
        <Row gutter={[16, 8]} className="content-profit-reconciliation">
          <Col span={24}>
            {(dataOrderReconciliationDetail?.status === "CANCELED" ||
              dataOrderReconciliationDetail?.status === "REJECTED") && (
              <ReasonBlock
                isCanceled={dataOrderReconciliationDetail?.status === "CANCELED"}
                isRejected={dataOrderReconciliationDetail?.status === "REJECTED"}
                rejectedReason={dataOrderReconciliationDetail?.rejectedReason}
                fullEvents={order?.fullEvents}
                loading={loadingOrderReconciliationDetail}
              />
            )}
          </Col>
          <Col span={24}>
            <ControlState data={dataOrderReconciliationDetail} visible={visible} />
          </Col>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={16} className="card-block">
                <OrderInfo loading={loadingOrderReconciliationDetail} order={order} />
              </Col>
              <Col span={8} className="card-block">
                <PaymentInfo loading={loadingOrderReconciliationDetail} order={order} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <ProductList dataSource={order?.items} loading={loadingOrderReconciliationDetail} />
          </Col>
        </Row>
      }
    />
  );
}

export default ProfitReconciliationDetail;
