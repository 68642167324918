import { Typography } from "antd";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip";

function EllipsisText({ title, line = 1, isLink = false, link, limit = 60, ...rest }) {
  const style = {
    display: "-webkit-box",
    height: `${1.3 * 16 * line}px`,
    WebkitLineClamp: line?.toString(),
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textAlign: !title && "center",
  };

  const content = isLink ? (
    <Typography.Link {...rest}>{title || "--"}</Typography.Link>
  ) : (
    <Typography.Text {...rest}>{title || "--"}</Typography.Text>
  );

  return (
    <div style={style}>
      <Tooltip title={title}>{link ? <Link to={link}>{content}</Link> : content}</Tooltip>
    </div>
  );
}

export default EllipsisText;
