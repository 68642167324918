/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import ListByImportSerial from "./ListByImportSerial";
import ListBySerial from "./ListBySerial";

function useList() {
  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY.activeKey || "listBySerial");
  const [activeKeyStatus, setActiveKeyStatus] = useState(GET_QUERY.key);

  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    setActiveKeyStatus("listByImportSerial");
    buildQueryString({
      params:
        activeKey === "listByImportSerial" ? { activeKey: activeKey } : { activeKey: activeKey, key: "listBySerial" },
    });
  };

  const handleChangeTabStatus = (value) => {
    setActiveKeyStatus(value);
  };

  useEffect(() => {
    buildQueryString({
      params: { activeKey: GET_QUERY.activeKey },
    });
  }, [GET_QUERY.activeKey]);

  return { handleChangeTab, handleChangeTabStatus, activeKeyMain, activeKeyStatus };
}

export default useList;
