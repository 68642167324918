import { Checkbox, Typography } from "antd";
import DividerCustom from "components/_storybooks/DividerCustom";
import EllipsisText from "components/_storybooks/EllipsisText";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import TagCustom from "components/_storybooks/TagCustom";
import CustomCard from "components/CustomCard";
import CustomTable from "components/CustomTable";
import { formatCurrency } from "utils/helperFuncs";

function ProductList({ loading, dataSource = [] }) {
  const columns = [
    {
      title: "STT",
      width: 60,
      align: "center",
      fixed: "left",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Thông số",
      width: 250,
      fixed: "left",
      render: (_, record) => {
        return (
          <SpaceCustom>
            <Typography.Link strong>{record?.product?.code}</Typography.Link>
            <EllipsisText title={record?.product?.name} />
          </SpaceCustom>
        );
      },
    },
    {
      title: "Xuất VAT",
      width: 100,
      align: "center",
      render: (_, record) => {
        return <Checkbox style={{ opacity: 0.5 }} value={record?.vat} disabled />;
      },
    },

    {
      title: "Số lượng",
      align: "center",
      width: 100,
      render: (_, record) => {
        return record?.quantity;
      },
    },

    {
      title: "Đơn giá đại lý",
      align: "right",
      width: 150,
      render: (_, record) => {
        return formatCurrency(record?.price);
      },
    },

    {
      title: "Thành tiền đại lý",
      align: "right",
      width: 150,
      render: (_, record) => {
        return <Typography.Link>{formatCurrency(record?.total)}</Typography.Link>;
      },
    },

    {
      title: "Đơn giá khách hàng",
      width: 170,
      align: "right",
      render: (_, record) => {
        return formatCurrency(record?.customerPrice);
      },
    },

    {
      title: "Thành tiền khách hàng",
      width: 180,
      align: "right",
      render: (_, record) => {
        return <Typography.Link strong>{formatCurrency(record?.customerTotal)}</Typography.Link>;
      },
    },

    {
      title: "Bảo hành (tháng)",
      width: 100,
      align: "center",
      render: (_, record) => {
        return record?.productWarranty;
      },
    },

    {
      title: "Được nợ (ngày)",
      width: 100,
      align: "center",
      render: (_, record) => {
        return record?.maxDaysOfDebt;
      },
    },

    {
      title: "Lợi nhuận của đại lý",
      width: 140,
      align: "right",
      fixed: "right",
      render: (_, record) => {
        return (
          <TagCustom color="#E9F9E9" style={{ color: "#0EAB10" }}>
            {formatCurrency(record?.customerTotal - record?.total)}
          </TagCustom>
        );
      },
    },
  ];

  return (
    <CustomCard
      content={
        <SpaceCustom gap={12}>
          <DividerCustom fontWeight="bold" children={"Danh sách sản phẩm"} margin={0} />
          <CustomTable loading={loading} columns={columns} dataSource={dataSource} />
        </SpaceCustom>
      }
    />
  );
}

export default ProductList;
