import { Typography } from "antd";
import DividerCustom from "components/_storybooks/DividerCustom";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import TagCustom from "components/_storybooks/TagCustom";
import CustomCard from "components/CustomCard";
import CustomDescriptions from "components/CustomDescriptions";
import { formatCurrency } from "utils/helperFuncs";

function PaymentInfo({ loading, order }) {
  return (
    <CustomCard
      content={
        <SpaceCustom gap={12}>
          <DividerCustom fontWeight="bold" children={"Hóa đơn"} margin={0} />
          <CustomDescriptions
            loading={loading}
            bordered={false}
            colon={false}
            labelWidth={"50%"}
            contentWidth={"50%"}
            labelStyle={{
              fontWeight: "normal",
            }}
            contentStyle={{
              justifyContent: "flex-end",
            }}
            paddingView="0 10px 0 10px"
            title={<Typography.Text style={{ fontSize: 14 }}>Hóa đơn cho khách của đại lý</Typography.Text>}
            contentMapping={[
              {
                label: "Tổng tạm tính khách hàng",
                value: formatCurrency(order?.customerSubTotal),
              },
              {
                label: "Phí vận chuyển",
                value: formatCurrency(order?.customerShippingFee),
              },
              {
                label: "Phí dịch vụ",
                value: formatCurrency(order?.customerTotalServiceProduct),
              },
              {
                label: <Typography.Link strong>Tổng thanh toán của khách</Typography.Link>,
                value: <Typography.Link strong>{formatCurrency(order?.customerTotal)}</Typography.Link>,
              },
            ]}
          />
          <DividerCustom dashed margin={0} borderTopColor="#D8DFEA" />
          <CustomDescriptions
            loading={loading}
            bordered={false}
            colon={false}
            labelWidth={"50%"}
            contentWidth={"50%"}
            labelStyle={{
              fontWeight: "normal",
            }}
            contentStyle={{
              justifyContent: "flex-end",
            }}
            paddingView="0 10px 0 10px"
            title={<Typography.Text style={{ fontSize: 14 }}>Hóa đơn cho đại lý</Typography.Text>}
            contentMapping={[
              {
                label: "Doanh thu bán hàng",
                value: formatCurrency(order?.customerSubTotal),
              },
              {
                label: "Tổng thành tiền đại lý",
                value: formatCurrency(order?.subTotal),
              },
              {
                label: "Giảm giá đơn hàng",
                value: formatCurrency(order?.discount),
              },
              {
                label: "Lợi nhuận trước chi phí",
                value: (
                  <Typography.Text strong style={{ color: "#0099FF" }}>
                    {formatCurrency(order?.customerSubTotal - (order?.subTotal - order?.discount))}
                  </Typography.Text>
                ),
              },
              {
                label: "Phí vận chuyển",
                value: formatCurrency(order?.shippingFee),
              },
              {
                label: "Phí dịch vụ",
                value: formatCurrency(order?.totalServiceProduct),
              },
              {
                label: <Typography.Link strong>Lợi nhuận của đại lý</Typography.Link>,
                value: (
                  <TagCustom color="#E9F9E9" style={{ color: "#0EAB10", margin: 0 }}>
                    {formatCurrency(order?.sellerProfitAmount)}
                  </TagCustom>
                ),
              },
            ]}
          />
        </SpaceCustom>
      }
    />
  );
}

export default PaymentInfo;
