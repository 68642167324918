import { Form, Radio, Space } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { has } from "lodash";
import { useState } from "react";

const OTHER = "OTHER";

function RadioGroupFormCustom({ radioGroupOptions = [], radioName = "reason", textInputName = "freeText", form }) {
  const [hidden, setHidden] = useState(true);

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();
    if (has(changedValues, radioName)) {
      if (changedValues.reason === OTHER) {
        setHidden(false);
      } else {
        setHidden(true);
        form.setFieldsValue({ ...formValues, freeText: undefined });
      }
    }
  };

  return (
    <Form form={form} onValuesChange={handleValuesChange}>
      <FormInput
        type={INPUT_TYPE.RADIO_GROUP}
        formItemOptions={{
          name: radioName,
          rules: [
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(radioName)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Vui lòng chọn lý do trước khi xác nhận"));
              },
            }),
          ],
          style: { marginBottom: 0 },
        }}
        inputChildren={
          <Space direction="vertical">
            {radioGroupOptions.map((item, index) => (
              <Radio key={index} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Space>
        }
      />
      <FormInput
        type={INPUT_TYPE.TEXT_AREA}
        formItemOptions={{
          name: textInputName,
          hidden,
          rules: [
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(radioName) !== OTHER) {
                  return Promise.resolve();
                }
                if (getFieldValue(radioName) === OTHER && value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Không được để trống trường này"));
              },
            }),
          ],
          style: { marginBottom: 0 },
        }}
        inputOptions={{ placeholder: "Nhập lý do", maxLength: 255 }}
      />
    </Form>
  );
}

export default RadioGroupFormCustom;
