import { Button, Col, Row } from "antd";
import CustomCard from "components/CustomCard";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { getQuery } from "utils/helperFuncs";
import ListByImportSerial from "./ListByImportSerial";
import ListBySerial from "./ListBySerial";
import useList from "./useList";

function ImportSerial() {
  const { handleChangeTab, activeKeyMain } = useList();
  const GET_QUERY = getQuery();
  const history = useHistory();

  const tabList = [
    {
      key: "listBySerial",
      tab: t("serialIE.bySerial"),
    },
    {
      key: "listByImportSerial",
      tab: t("serialIE.byImportSerial"),
    },
  ];

  const contentList = {
    [`listBySerial`]: <ListBySerial />,
    [`listByImportSerial`]: <ListByImportSerial />,
  };

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          pageTitle={t("serialIE.listImportSerial")}
          routes={[
            { path: "/import-serial", name: t("serialIE.serial") },
            { path: "/", name: t("serialIE.importSerial") },
          ]}
        />
      </Col>
      <Col span={24}>
        <CustomCard
          tabList={tabList}
          onTabChange={handleChangeTab}
          defaultActiveTabKey={activeKeyMain}
          contentList={contentList}
          activeTabKey={activeKeyMain}
          tabProps={{
            tabBarExtraContent: (
              <Button
                onClick={() => {
                  history.push({
                    pathname: "/import-serial/create",
                  });
                }}
                type="primary"
              >
                {t("serialIE.create")}
              </Button>
            ),
          }}
        />
      </Col>
    </Row>
  );
}

export default ImportSerial;
