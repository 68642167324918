import { SORT_TYPES } from "features/Serial/constant";
import { useOrderReconciliationDetail, useOrderReconciliationListQuery } from "hooks/orderReconciliation";
import useDebounce from "hooks/useDebounce";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { getQuery } from "utils/helperFuncs";
import { formatBuildQueryString, PROFIT_RECONCILIATION } from "../constants";
import moment from "moment";

function useProfitReconciliationList() {
  const GET_QUERY = getQuery(false);
  const [params, setParams] = useState({
    activeKey: GET_QUERY.activeKey,
    filters: {
      query: GET_QUERY.query || "",
      reconciliationStatuses: GET_QUERY.reconciliationStatuses || undefined,
      sellerIDs: GET_QUERY.sellerIDs,
      paymentMethods: GET_QUERY.paymentMethods,
      saleChannelIDs: GET_QUERY.saleChannelIDs,
      orderStatuses: GET_QUERY.orderStatuses,
      timeRange: GET_QUERY?.from
        ? {
            from: GET_QUERY?.from,
            to: GET_QUERY?.to,
          }
        : undefined,
      expiredDateStatuses: GET_QUERY.expiredDateStatuses,
      completedDateStatuses: GET_QUERY.completedDateStatuses,
      staffInChargeIDs: GET_QUERY.staffInChargeIDs,
      currentTime: GET_QUERY?.currentTime,
    },
    pagination: {
      limit: GET_QUERY?.limit || 10,
      offset: GET_QUERY?.offset || 0,
    },
    sort: [],
  });

  const [searchTerm, setSearchTerm] = useState(null);
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY?.activeKey || PROFIT_RECONCILIATION.ALL);
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const [orderReconId, setOrderReconId] = useState(undefined);
  const [previousOffset, setPreviousOffset] = useState(params.pagination.offset);
  const debouncedValue = useDebounce(searchTerm?.trim()?.toString());
  const { orderReconciliationList, loading, paginationData } = useOrderReconciliationListQuery(params);
  const { dataOrderReconciliationDetail, loadingOrderReconciliationDetail, handleGetOrderReconciliationDetail } =
    useOrderReconciliationDetail(orderReconId);

  const updateParams = (newParams) => {
    const updatedParams = {
      ...params,
      ...newParams,
    };
    setParams(updatedParams);
    formatBuildQueryString({
      ...updatedParams,
      activeKey: GET_QUERY.activeKey,
      from: GET_QUERY.from,
      to: GET_QUERY.to,
    });
  };

  const handleVisibleDrawerDetail = async (value) => {
    setVisibleDrawerDetail(true);
    setOrderReconId(value);
    await handleGetOrderReconciliationDetail(value);
  };

  const handleCloseVisibleDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    const newParams = {
      ...params,
      filters: {
        reconciliationStatuses: activeKey === "ALL" ? undefined : activeKey,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    };
    setParams(newParams);
    formatBuildQueryString({
      ...newParams,
      activeKey: activeKey,
    });
  };

  const handleChangeParams = (value) => {
    updateParams({
      filters: {
        ...params.filters,
        ...value,
        currentTime: moment().format("YYYY/MM/DD HH:mm:ss"),
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    });
  };

  const onFilter = (key, value) => {
    handleChangeParams({ [key]: value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    updateParams({
      pagination: {
        ...params.pagination,
        offset: (current - 1) * pageSize,
      },
      sort: sorter?.order ? [{ by: "CREATED_AT", orderType: SORT_TYPES[`${sorter?.order}`] }] : [],
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const isQueryCleared = debouncedValue.trim() === "";

      if (!isQueryCleared) {
        setPreviousOffset(params.pagination.offset);
      }

      updateParams({
        filters: {
          ...params.filters,
          query: isQueryCleared ? "" : debouncedValue.trim(),
        },
        pagination: {
          ...params.pagination,
          offset: isQueryCleared ? previousOffset : 0,
        },
      });
    }
  }, [debouncedValue]);

  return {
    params,
    restTableList: {
      loading,
      dataSource: orderReconciliationList,
      rowKey: (record) => record?.id,
      pagination: {
        total: paginationData?.total,
        pageSize: paginationData?.limit,
        current: paginationData?.offset / paginationData?.limit + 1 || 1,
        showSizeChanger: false,
      },
      onChange: handleTableChange,
    },
    activeKeyMain,
    visibleDrawerDetail,
    orderReconId,
    dataOrderReconciliationDetail,
    loadingOrderReconciliationDetail,
    handleChangeTab,
    handleVisibleDrawerDetail,
    handleCloseVisibleDrawerDetail,
    onFilter,
    handleSearch,
    handleChangeParams,
  };
}

export default useProfitReconciliationList;
